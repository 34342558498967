<template>
    <div>
        <el-card class="mt-2 ml-4 mr-4 bg-white border-white">

            <div style="width: 100%; margin: auto; " class="font-semibold text-lg">
                <el-divider direction="vertical" style="color: #7d89c3;width: 5px;"></el-divider>审核管理
				<div class="flex justify-end text-xs mt-4">*最近一个更新的时间为：{{tableData[0].update_time}}</div>
            </div>
            <!-- 表格 -->
            <el-tabs v-model="activeName" @tab-click="handleClick" class="mt-4 ml-4">
                <el-tab-pane :label="'全部 '+total" name="first">
                   <el-table :data="tableData" border style="width: 100%;margin-top: -1rem;">
                       <el-table-column prop="id" label="" min-width="5%">
                       </el-table-column>
                       <el-table-column prop="name" label="园区名称" min-width="30%">
                       </el-table-column>
                       <el-table-column prop="count" label="班级数量" min-width="15%">
                       </el-table-column>
                       <el-table-column prop="update_time" label="更新时间" min-width="20%">
                       </el-table-column>
                       <el-table-column label="详情" min-width="15%">
                           <template slot-scope="scope">
                               <el-button size="small" type="text" @click="classDetail(scope.row)">详情</el-button>
                           </template>
                       </el-table-column>
                   </el-table>
                </el-tab-pane>
				<!-- <div class="flex justify-end text-xs mt-4">*该表格的已到状态会在当日的24:00后自动更新</div> -->
            </el-tabs>
        </el-card>
    </div>
</template>

<script>
    export default {

        data() {
            return {
                form: {
                    imgUrl: "", //图片字段
                    platName: "", //自定义字段
                },
                activeName: 'first',
                labelWidth: "100px",
                searchData: {
                    name: ''
                },
                tableData: [],
                // 对话框
                isAdd: false, //区分添加和编辑
                dialog: false,
                // 分页
                total: 0,
                currentPage: 1,
                school_id:''
            };
        },

        computed: {},
        components:{
        },
        watch: {},
        mounted() {
            this.school_id=window.sessionStorage.getItem("school_id")
            this.getList()
        },
        methods: {
            handleClick(tab, event) {
                console.log(tab, event);
            },
            //跳转到班级详情
            classDetail(row){
                window.sessionStorage.setItem("nursery_id",row.id)
               this.$router.push({name:'examine'})
            },
            //跳转到学生管理
            studentDetail(row){
              
            },
            // 获取基本数据
            async getList() {
                const RES = await this.http.post("/admin/campus/all", {
                    limit: 20,
                    page: this.currentPage,
                    school_id:this.school_id
                });
                if (RES.code == 200) {
                     this.tableData = RES.data;
                    this.total = RES.total;
                }
            },
            // 编辑
            handleEdit(row) {
                this.form = JSON.parse(JSON.stringify(row)); //编辑给表单赋值的时候，需要把数据深度拷贝
            },
            //分页
            pageChange(index) {
                this.currentPage = index._currentPage;
                this.getList();
            },
        },
    };
</script>
<style lang="scss" scoped>
     /deep/.el-dialog__headerbtn .el-dialog__close {
        color: black;
    }

    /deep/.el-dialog {
        height: 400px;
    }

    /deep/.el-dialog__header {
        background-color: #9473ee;
        padding: 10px 10px 10px;
    }

    /deep/.el-tabs__header {
        background-color: #d9dceca3;
    }

    /deep/.el-dialog__headerbtn {
        top: 10px;
    }

    /deep/.el-dialog__title {
        color: white;
        font-size: 1rem;
    }

    /deep/.el-table th {
        background-color: white !important;
    }

    /deep/.el-tabs__nav {}

    /deep/.el-tabs__item.is-active {
        background-color: #d4caf1;
        color: #535c8d;
    }

    /deep/.el-tabs__active-bar {
        background-color: #535c8d;
		margin-left: -1.8rem;
    }

    /deep/.el-table td,
    .el-table th {
        text-align: center;
    }

    /deep/.el-tabs--top .el-tabs__item.is-top:nth-child(2){
        padding: 0 30px;
    }

    /deep/.el-table th {
        text-align: center;
        background: rgb(236, 235, 235);
    }

    /deep/ .avatar-uploader .el-upload {
        border: 1px dashed hsl(0, 0%, 85%);
        cursor: pointer;
        position: relative;
        overflow: hidden;
        background: rgb(204, 204, 204);
    }

    /deep/.avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }

    /deep/ .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 200px;
        height: 150px;
        line-height: 150px;
        text-align: center;
    }

    /deep/ .avatar {
        width: 200px;
        height: 150px;
        display: block;
    }

    /deep/.el-form-item {
        margin-bottom: 5px;
    }

    /deep/.el-dialog__body {
        padding: 0px;
    }

    /deep/.el-divider--horizontal {
        margin: 0px;
    }


    /deep/.el-tabs__header {
        background-color: #f8f8f8;
    }

      /deep/.el-tabs__item.is-active {
        background-color: #ecedf3;
        color: #535c8d;
    }
    /deep/.el-dialog__header {
        background-color: #9473ee;
        padding: 10px 10px 10px;
    }
    /deep/.el-tabs__active-bar {
        background-color: #7d89c3;
        margin-left: -1.8rem;
    }
    /deep/.el-button--primary.is-plain{
            border-color: inherit;
    }
</style>